<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Bank Details <small>(For withdrawal purpose)</small></b-card-title>
      <ul
        v-if="userData"
        class="list-unstyled my-1"
      >
        <li>
          <strong>Bank:</strong> <span class="align-middle">{{ userData.bank_name }}</span>
        </li>
        <li class="my-25">
          <strong>Number:</strong> <span class="align-middle">{{ userData.account_number }}</span>
        </li>
        <li>
          <strong>Name:</strong> <span class="align-middle">{{ userData.account_name }}</span>
        </li>
      </ul>
      <div
        v-else
        class="text-center"
      >
        <p>No bank details </p>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardBody, BCardTitle,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    return {
      permissionsData,
    }
  },
}
</script>

<style>

</style>
